import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/home/Home";
import HomeData from "./pages/home/HomeData";
import HomeView from "./pages/home/HomeView";

import DataTable from "./pages/tables/datatable";
import NodeTable from "./pages/tables/nodetable";
import RelateTable from "./pages/tables/relatetable";
import TitleTable from "./pages/tables/titletable";

import UserTable from "./pages/tables/usertable";

import HomeEdit from "./pages/home/HomeEdit";
import LinkTable from "./pages/tables/linktable";
import NewFile from "./pages/newfile/NewFile";
import NewFileData from "./pages/newfile/NewFileData";
import NewFileView from "./pages/newfile/NewFileView";
import AuthContextProvider from "./context/authContext";

import { LanguageProvider } from "./context/languageContext";
import i18n from "./context/i18n";
import LanguageWrapper from "./context/i18n";

import NewLoader from "./components/loaders/newloader";
import ModelDiagramPreViewNewFile from "./pages/project01/adminModelPreViewNewFile";

import GroupFileView from "./pages/newfile/GroupFileView";
import ShowGroupView from "./pages/newfile/ShowGroupView";

function App() {

const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const startTime = performance.now();

    const endTime = performance.now();
    const loadingTime = endTime - startTime;

    const minLoadingTime = 200;

    const waitTime = Math.max(0, minLoadingTime - loadingTime);

    const timer = setTimeout(() => {
      setIsLoading(false);
    }, waitTime);

    document.title = "xxplans.com";

    return () => clearTimeout(timer);
  }, []);

  return (
    <AuthContextProvider>
     <LanguageProvider>
     <LanguageWrapper />
      <div className="App">
      {isLoading ? (
          <NewLoader /> // 显示 Loader
        ) : (
        <BrowserRouter>
          <Routes>
            <Route path="/">
              <Route index element={<Home />} />
              <Route path="/edit1/" element={<NewFile />} />
              <Route path="/data1/" element={<NewFileData />} />
              <Route path="/view1/" element={<NewFileView />} />
              <Route path="/edit2/" element={<HomeEdit />} />
              <Route path="/data2/" element={<HomeData />} />
              <Route path="/view2/" element={<HomeView />} />

              <Route
                  path="/view3/"
                  element={<ModelDiagramPreViewNewFile />}
                />
            
              <Route path="/groupview1/" element={<ShowGroupView />} />

              <Route path="/group1/" element={<GroupFileView />} />

              <Route path="/nodes/" element={<NodeTable />} />
              <Route path="/links/" element={<LinkTable />} />
              <Route path="/users/" element={<UserTable />} />
              <Route path="/relate/" element={<RelateTable />} />
              <Route path="/datas/" element={<DataTable />} />
              <Route path="/titles/" element={<TitleTable />} />
            </Route>
          </Routes>
        </BrowserRouter>
       )}
      </div>
</LanguageProvider>
    </AuthContextProvider>
  );
}

export default App;
